const JOB_SEARCH_STATUS_VALUES = [
  { label: 'Not looking', value: 'not_looking' },
  { label: 'Casually browsing', value: 'casually_browsing' },
  { label: 'Actively looking', value: 'actively_looking' },
];

const EMPLOYMENT_TYPES = [
  { label: 'Full-time', value: 'full_time' },
  { label: 'Part-time', value: 'part_time' },
  { label: 'Contractor', value: 'contractor' },
  { label: 'Intern', value: 'intern' },
  { label: 'Volunteer', value: 'volunteer' },
  { label: 'Other', value: 'other_employment_type' },
];

const SENIORITIES = [
  {
    value: 'entry_level',
    label: 'Entry level (Less than 2 years in a similar role, includes interns)',
  },
  {
    value: 'experienced',
    label: 'Experienced (2+ years in a similar role)',
  },
  {
    value: 'manager',
    label: 'Manager (Entry-to mid-level managers responsible for managing a team)',
  },
  {
    value: 'director',
    label: 'Director (Senior-level managers responsible for managing teams of managers)',
  },
  {
    value: 'executive',
    label: 'Executive (Executive-level managers and above, including C-level positions)',
  },
];

const YES_OR_NO_VALUES = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const PROFILE_VISIBILITIES = [
  { label: 'Visible to companies', value: 'visible' },
  { label: 'Hidden from some companies and members', value: 'restricted' },
  { label: 'Hidden from companies', value: 'hidden' },
];

const JOB_ALERT_FREQUENCIES = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
];

const SCORE_OPTIONS = {
  1: 'Low info personally and professionally',
  2: 'High confidence personally; low info professionally',
  3: 'Low info personally; high confidence professionally',
  4: 'High confidence personally and professionally',
  5: 'High confidence personally and professionally, plus, I would bet my career on them',
};
const RELATIONSHIP_LEVEL_OPTIONS = {
  1: 'I’ve worked with [Member Name]',
  2: 'I’ve interviewed [Member Name]',
  3: 'I’ve been mentored by [Member Name]',
  4: 'I’ve mentored [Member Name]',
  5: 'I don’t know [Member Name] personally',
};

const SETTINGS = {
  companyName: 'Getro',
  membersPerPage: 20,
  talentGroupDefaultColor: '#a6b1bf',
};

export {
  SETTINGS,
  JOB_SEARCH_STATUS_VALUES,
  EMPLOYMENT_TYPES,
  SENIORITIES,
  YES_OR_NO_VALUES,
  PROFILE_VISIBILITIES,
  JOB_ALERT_FREQUENCIES,
  SCORE_OPTIONS,
  RELATIONSHIP_LEVEL_OPTIONS,
};
