import humps from 'humps';
import getConfig from 'next/config';
import SentryAPI from './SentryAPI';

const { publicRuntimeConfig } = getConfig();

class SegmentAPI {
  static async page(data) {
    if (typeof window !== 'undefined') {
      const snakeCaseData = humps.decamelizeKeys(data);
      window.analytics.page(snakeCaseData);
    }
  }

  static async track(name, properties) {
    if (typeof window === 'undefined') return;
    try {
      const snakeCaseProperties = humps.decamelizeKeys(properties);
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`analytics trackEvent: ${name}: ${JSON.stringify(properties)}`);
      }
      window.analytics.track(name, snakeCaseProperties);
    } catch (error) {
      SentryAPI.trackError('analytics track page', { error, event: name, properties });
    }
  }

  static async identify(userId, network, data) {
    if (typeof window === 'undefined') return;
    try {
      const snakeCaseData = humps.decamelizeKeys(data);
      window.analytics.identify(userId, snakeCaseData);
      SegmentAPI.segmentPosthog();
    } catch (error) {
      SentryAPI.trackError('analytics identify user', { error, userId, data, network });
    }
  }

  static async identifyAnonymousUser(network) {
    if (typeof window === 'undefined') return;

    try {
      window.analytics.identify();
      SegmentAPI.segmentPosthog();
    } catch (error) {
      SentryAPI.trackError('analytics identify anonymous user', { error, network });
    }
  }

  static async segmentPosthog() {
    try {
      import('posthog-js').then(async ({ posthog }) => {
        if (publicRuntimeConfig.postHogKey) {
          window.analytics.ready(() => {
            posthog.init(publicRuntimeConfig.postHogKey, {
              api_host: 'https://app.posthog.com',
              segment: window.analytics,
              capture_pageview: false,
              loaded: () => {
                window.analytics.page();
              },
            });
          });
        }
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

export default SegmentAPI;
