import qs from 'query-string';

export const objectToQueryString = ({ query }) => {
  if (!query) {
    throw new Error('No query object found');
  }
  return qs.stringify(query, { encode: true });
};

const validQueryParams = [
  'q',
  'page',
  'filter',
  'job_functions',
  'searchable_locations',
  'searchable_location_option',
  'industry_tags',
  'head_count',
  'stage',
  'organization.id',
  'organization.slug',
  'organization.industry_tags',
  'organization.head_count',
  'organization.stage',
];

export const sanitizeQuery = (query) => {
  const result = {};
  if (query && Object.keys(query).length > 0) {
    Object.keys(query).forEach((key) => {
      if (validQueryParams.indexOf(key) > -1) {
        result[key] = query[key];
      } else if (key.includes('organization.topics')) {
        result[key] = query[key];
      }
    });
  }
  return result;
};

const excludedDomain = new Set(['arius.hirepro.in']);

export const addUTMSource = (applicationUrl, networkName) => {
  try {
    const parts = new URL(applicationUrl);
    if (excludedDomain.has(parts.hostname.replace('www.', ''))) return applicationUrl;

    const urlParams = new URLSearchParams(parts.search);

    ['utm_source', 'utm_medium', 'gh_src'].forEach((utm) => {
      if (urlParams.has(utm)) {
        urlParams.delete(utm);
      }
      urlParams.append(utm, utm === 'utm_medium' ? 'getro.com' : `${networkName} job board`);
    });

    parts.search = urlParams.toString();

    return parts.toString();
  } catch (error) {
    return applicationUrl;
  }
};
